<template>
  <div>
    <template v-if='loading'>
      <div class='p-24 bg-white rounded shadow-sm'>
        <div class='lrm h-16 rounded-full'
          v-for='index in 3'
          :key='index'>
        </div>
      </div>
    </template>
    <template v-else>
      <slot/>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      loading: Boolean
    }
  }
</script>

<style lang='scss' scoped>
  .lrm {
    background-image: linear-gradient(to right, theme('colors.gray.300') 40%, theme('colors.gray.100'), theme('colors.gray.300') 60%);
    background-size: 400% 100%;
    animation: shine 2s ease-in-out infinite;

    &:not(:first-child) {
      @apply mt-16;
    }

    &:nth-child(3n + 2) {
      @apply w-3/5;
    }

    &:nth-child(3n + 3) {
      @apply w-4/5;
    }
  }

  @keyframes shine {
    from {
      background-position-x: 0;
    }

    to {
      background-position-x: -150%;
    }
  }
</style>
