<template>
  <span>
    <template v-if='convertedValue'>
      <span class='opacity-50'>~</span>
      {{ $n(convertedValue, { style: 'currency', currency: userCurrency, maximumFractionDigits: 0 }) }}
    </template>
    <template v-else>
      {{ $n(value, { style: 'currency', currency, minimumFractionDigits: 2 }) }}
    </template>
  </span>
</template>

<script>
  export default {
    props: {
      value: Number,
      currency: String
    },

    computed: {
      userCurrency () {
        return this.$store.state.app.currency
      },

      exchangeRates () {
        return this.$store.state.settings.exchangeRates
      },

      convertedValue () {
        let value = this.value
        let currency = this.currency
        let userCurrency = this.userCurrency
        let rates = this.exchangeRates?.rates

        if (!userCurrency || !rates) return
        if (currency === userCurrency) return
        if (isNaN(rates[currency]) || isNaN(rates[userCurrency])) return

        return value / rates[currency] * rates[userCurrency]
      }
    }
  }
</script>
