<template>
  <div>
    <img class='img'
      :src='plan.imageUrl || canvasImage'>
  </div>
</template>

<script>
  export default {
    props: {
      plan: Object
    },

    data: () => ({
      canvasImage: null
    }),

    mounted () {
      if (!this.plan.imageUrl) {
        let canvas = document.createElement('canvas')

        canvas.width = 480
        canvas.height = 640

        let context = canvas.getContext('2d')

        context.save()
        context.fillStyle = '#f7fafc'
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.restore()

        context.save()
        context.rotate(-Math.PI / 2)
        context.translate(-640, 0)
        context.font = 'bold 120px "Volte", sans-serif'
        context.fillStyle = '#3743bd'
        context.fillText(this.plan.operator, 60, 420)
        context.restore()

        this.canvasImage = canvas.toDataURL('image/jpeg')
      }
    }
  }
</script>

<style lang='scss' scoped>
  .img {
    @apply
      block
      h-full
      object-cover;
  }
</style>
