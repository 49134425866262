<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='tan m-auto'>
        <skeleton-loader-component :loading='!plan'>
          <template v-if='plan'>
            <div class='flex mt-8 text-gray-700'>
              <div class='flex cursor-pointer'
                @click='$router.back()'>
                <i class='icon'>arrow_back</i>
              </div>
              <div class='ml-16 text-lg font-bold'>{{ plan.name }}</div>
            </div>
            <card-element class='jaw grid mt-24'>
              <plan-image-component class='mea overflow-hidden mr-24 rounded'
                :plan='plan'/>
              <div class='ali'>
                <div class='text-xl'>{{ plan.name }}</div>
                <div class='flex mt-16'>
                  <i class='icon text-gray-700'>signal_cellular_alt</i>
                  <div class='ml-16'>{{ plan.networkType }}</div>
                </div>
              </div>
              <div class='bet mt-24'>
                <div class='flex justify-between'>
                  <div class='flex'>
                    <i class='icon text-gray-700'>language</i>
                    <div class='ml-16'>{{ $t('common.data') }}</div>
                  </div>
                  <div v-if='plan.data.value > 0'>{{ plan.data.value }} {{ plan.data.unit }}</div>
                  <div v-else>{{ $t('common.unlimited') }}</div>
                </div>
                <div class='flex justify-between pt-8 mt-8 border-gray-300 border-t'>
                  <div class='flex'>
                    <i class='icon text-gray-700'>date_range</i>
                    <div class='ml-16'>{{ $t('common.validity') }}</div>
                  </div>
                  <div>{{ plan.period.value }} {{ plan.period.unit }}</div>
                </div>
              </div>
            </card-element>
            <template v-if='plan.topups'>
              <header-element class='mt-24'>{{ $t('pages.plan.a6b24f58') }}</header-element>
              <card-element class='px-0 mt-24'>
                <carousel-component>
                  <div class='px-24'
                    v-for='topup in plan.topups'
                    :key='topup.slug'>
                    <div class='text-lg text-center'>
                      <price-text-component
                        :value='topup.price.value'
                        :currency='topup.price.currency'/>
                    </div>
                    <div class='flex justify-between mt-24'>
                      <div class='flex'>
                        <i class='icon text-gray-700'>language</i>
                        <div class='ml-16'>{{ $t('common.data') }}</div>
                      </div>
                      <div v-if='topup.data.value > 0'>{{ topup.data.value }} {{ topup.data.unit }}</div>
                      <div v-else>{{ $t('common.unlimited') }}</div>
                    </div>
                    <div class='flex justify-between pt-8 mt-8 border-gray-300 border-t'>
                      <div class='flex'>
                        <i class='icon text-gray-700'>date_range</i>
                        <div class='ml-16'>{{ $t('common.validity') }}</div>
                      </div>
                      <div>{{ topup.period.value }} {{ topup.period.unit }}</div>
                    </div>
                  </div>
                </carousel-component>
              </card-element>
            </template>
            <header-element class='mt-24'>{{ $t('pages.plan.4cb0e84b') }}</header-element>
            <card-element class='mt-24'>
              <div class='fas flex items-center'
                v-for='country in plan.countries'
                :key='country.code'>
                <img class='w-24'
                  :src='`flags/countries/${country.code.toLowerCase()}.svg`'>
                <div class='ml-16'>{{ country.name }}</div>
              </div>
            </card-element>
            <template v-if='plan.additionalInfo.length'>
              <header-element class='mt-24'>{{ $t('pages.plan.cc8864c1') }}</header-element>
              <card-element class='mt-24'>
                <div class='cha flex'
                  v-for='(info, index) in plan.additionalInfo'
                  :key='index'>
                  <div class='text-gray-700'>-</div>
                  <div class='ml-16'>{{ info }}</div>
                </div>
              </card-element>
            </template>
            <button-element class='is-block p-16 mt-24'
              :disabled='loading'
              @click.native='buyPlan'>
              <spinner-element class='mr-16 -ml-16 text-gray-500'
                v-if='loading'/>
              <div>
                <price-text-component
                  :value='plan.price.value'
                  :currency='plan.price.currency'/>
                — {{ $t('common.buy-now') }}
              </div>
            </button-element>
          </template>
        </skeleton-loader-component>
      </div>
    </div>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "unknown"'>{{ $t('pages.plan.9abbdc41') }}</div>
      </div>
    </snackbar-element>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import Carousel from '@/components/carousel'
  import PlanImage from '@/components/plan-image'
  import PriceText from '@/components/price-text'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'carousel-component': Carousel,
      'plan-image-component': PlanImage,
      'price-text-component': PriceText,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation
    },

    props: {
      slug: String
    },

    data: () => ({
      plan: null,
      error: '',
      loading: false
    }),

    computed: {
      user () {
        return this.$store.state.auth.user
      }
    },

    mounted () {
      this.fetchPlan()
    },

    methods: {
      async fetchPlan () {
        let slug = this.slug
        this.plan = await this.$store
          .dispatch('plans/getPlan', { slug })
      },

      async buyPlan () {
        if (!this.user) {
          this.$router.push({ name: 'login' })
          return
        }

        try {
          this.loading = true

          let slug = this.slug
          let order = await this.$store
            .dispatch('plans/buyPlan', { slug })

          this.$router.push({ name: 'order', params: { id: order.id } })
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang='scss' scoped>
  .tan {
    max-width: 480px;
  }

  .jaw {
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto 1fr;
  }

  .mea {
    grid-area: 1 / 1 / 2 / 2;
    height: 90px;
  }

  .ali {
    grid-area: 1 / 2 / 2 / 3;
  }

  .bet {
    grid-area: 2 / 1 / 3 / 3;
  }

  .fas {
    &:not(:first-child) {
      @apply mt-16;
    }
  }

  .cha {
    &:not(:first-child) {
      @apply mt-8;
    }
  }

  @screen desktop {
    .mea {
      grid-area: 1 / 1 / 3 / 2;
      height: 240px;
    }

    .bet {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
</style>
