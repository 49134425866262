<template>
  <div>
    <div class='overflow-hidden'
      @touchstart='handleTouchStart'
      @touchmove='handleTouchMove'
      @touchend='handleTouchEnd'>
      <div class='con flex'
        ref='container'>
        <slot/>
      </div>
    </div>
    <div class='flex justify-center mt-24'>
      <div class='dot mx-4 w-8 h-8 rounded-full transition duration-200 cursor-pointer'
        v-for='index in slideCount'
        :class='{ "active": index === currentSlide + 1 }'
        :key='index'
        @click='selectSlide(index - 1)'>
      </div>
    </div>
  </div>
</template>

<script>
  import anime from 'animejs'

  export default {
    data: () => ({
      currentSlide: 0
    }),

    computed: {
      slideCount () {
        return this.$slots.default.length
      }
    },

    methods: {
      handleTouchStart (event) {
        this.startTouch = event.touches[0]
        this.dragging = true
      },

      handleTouchMove (event) {
        if (!this.dragging) return

        let currentTouch = event.touches[0]
        let startTouch = this.startTouch
        let delta = currentTouch.clientX - startTouch.clientX

        let container = this.$refs.container
        let width = container.offsetWidth
        let offset = -1 * this.currentSlide * width

        anime.set(container, {
          translateX: (offset + delta) / width * 100 + '%'
        })

        this.dragOffset = delta
      },

      handleTouchEnd () {
        let maxSlide = this.slideCount - 1
        let nextSlide = this.currentSlide

        let minOffset = 40
        let offset = this.dragOffset

        if (Math.abs(offset) > minOffset) {
          nextSlide = offset < 0
            ? Math.min(nextSlide + 1, maxSlide)
            : Math.max(nextSlide - 1, 0)
        }

        this.selectSlide(nextSlide)
        this.dragging = false
      },

      selectSlide (nextSlide) {
        let container = this.$refs.container
        let from = anime.get(container, 'translateX')
        let to = -1 * nextSlide * 100 + '%'

        anime({
          targets: container,
          translateX: [from, to],
          duration: 5 * Math.abs(parseFloat(from) - parseFloat(to)),
          easing: 'easeOutCubic'
        })

        this.currentSlide = nextSlide
      }
    }
  }
</script>

<style lang='scss' scoped>
  .con {
    will-change: transform;
  }

  .con > * {
    @apply
      min-w-full
      select-none;
  }

  .dot {
    @apply bg-gray-500;

    &.active {
      @apply bg-primary;
    }
  }

  @screen desktop {
    .con > * {
      @apply select-auto;
    }

    .dot {
      &:hover {
        @apply bg-primary;
      }
    }
  }
</style>
